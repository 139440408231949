import { Color, Heading, Text } from '@gradehub/gooseberry'
import React from 'react'
import styled from 'styled-components'
import ThemeColor from '../constants/ThemeColor'
import SocialLinks from '../components/SocialLinks'
import SiteHead from '../components/SiteHead'

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledTop = styled.div`
    display: flex;
    width: 100%;
    background: ${ThemeColor.moss} linear-gradient(120deg, ${ThemeColor.midnightSky}, ${ThemeColor.moss});
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
`

const StyledImage = styled.img`
    margin-bottom: 20px;
    border-radius: 4px;
`

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 100px;
    padding-right: 16px;
    padding-left: 16px;
    max-width: 800px;
`

const StyledSection = styled.div`
    margin-top: 20px;
`

const StyledGreenHeading = styled(Heading).attrs({
    level: 3
})`
    margin-top: 40px;
    color: ${ThemeColor.moss};
`

const StyledInfo = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
`

const StyledContact = styled.a`
    opacity: 0.7;
`

const Page: React.FC = () =>
    <StyledContainer>
        <SiteHead/>
        <StyledTop>
            <StyledImage
                width={120}
                height={120}
                src='/sam.png'
                alt='Sam Davies profile photo'
            />

            <Heading center level={1} color={Color.white}>
                Sam Davies
            </Heading>

            <SocialLinks/>

            <StyledContact
                href='mailto:samdavies@live.co.uk?Subject=Contact%20Sam%20Davies'
                target='_top'
            >
                <Text color={Color.white}>
                    samdavies@live.co.uk
                </Text>
            </StyledContact>
        </StyledTop>
        <StyledBody>

            <StyledGreenHeading>
                Employment
            </StyledGreenHeading>

            <StyledSection>
                <Text>
                    Nov 2018 - Nov 2020
                </Text>
                <Text bold>
                    CTO - STYLiNDEX
                </Text>
            </StyledSection>

            <StyledSection>
                <Text>
                    Jan 2017 - Nov 2018
                </Text>
                <Text bold>
                    Lead Software Engineer - Unibuddy
                </Text>
            </StyledSection>

            <StyledSection>
                <Text>
                    Jun 2016 - Jan 2017
                </Text>
                <Text bold>
                    Software Engineer - Amazon IMDb
                </Text>
            </StyledSection>

            <StyledSection>
                <Text>
                    Jun 2015 - Sep 2015
                </Text>
                <Text bold>
                    Intern Software Engineer - Factset
                </Text>
            </StyledSection>

            <StyledSection>
                <Text>
                    Apr 2014 - Sep 2014
                </Text>
                <Text bold>
                    Software Developer - Systech
                </Text>
            </StyledSection>

            <StyledGreenHeading>
                Education
            </StyledGreenHeading>

            <StyledSection>
                <Text>
                    2012 - 2016
                </Text>
                <Text bold>
                    Software Engineering, First Class with Honours
                </Text>
                <Text bold>
                    The University Of Edinburgh
                </Text>
            </StyledSection>

            <StyledGreenHeading>
                Project Highlights
            </StyledGreenHeading>

            <StyledSection>
                <Text bold>
                    CTO - STYLiNDEX
                </Text>
                <StyledSection>
                    <Text>
                        <ul>
                            <li>
                                Migrated a legacy python monolith to 15+ independently deployable microservices.
                            </li>
                            <li>
                                Automated the build, deployment and testing of front
                                and backend code with Github Actions CI.
                            </li>
                            <li>
                                Oversaw the hiring process for all tech employees.
                            </li>
                            <li>
                                Managed 20 people, with multiple teams of engineers, designers and product managers.
                            </li>
                            <li>
                                In addition to engineering, heavily influenced the design and UX of all Stylindex
                                products.
                            </li>
                        </ul>
                    </Text>
                </StyledSection>
            </StyledSection>

            <StyledSection>
                <Text bold>
                    Owner - GradeHub
                </Text>
                <a href='https://gradehub.io/'>https://gradehub.io/</a>
                <StyledSection>
                    <Text>
                        GradeHub is a solo project I use to showcase my technical
                        ability and explore new technologies. Design, Engineering,
                        DevOps are all done by me. The website is a university student
                        calculator, with reviews and analytics for each module and over
                        500k monthly visitors.
                    </Text>
                    <br/>
                    <ul>
                        <li>
                            10+ Rust microservices including: authentication, stripe payments, real time messaging,
                            search.
                        </li>
                        <li>
                            Typescript/React on the frontend with Apollo and Next JS.
                        </li>
                        <li>
                            Automated build, deployment, testing using Docker and Github Actions CI.
                        </li>
                        <li>
                            Test Driven Development throughout front and backend
                        </li>
                    </ul>

                </StyledSection>
            </StyledSection>

            <StyledSection>
                <Text bold>
                    Lead Software Engineer - Unibuddy
                </Text>
                <StyledSection>
                    <Text>
                        Unibuddy has contracts with over 100 universities, are live with over
                        1 million students and has over 7 different products. As the first Engineer in
                        the company I lead the engineering, mentoring junior programmers and scaled the development team
                        over 2 years.
                    </Text>
                </StyledSection>
            </StyledSection>

            <StyledInfo>
                <a href='https://www.instagram.com/5amdavies'>
                    Crafted with 🍬 by Sam Davies.
                </a>
            </StyledInfo>
        </StyledBody>
    </StyledContainer>

export default Page

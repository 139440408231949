import styled from 'styled-components'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import React from 'react'

const StyledIcons = styled.div`
    display: flex;
    flex-direction: row;
`

const StyledIcon = styled.div`
    font-size: 28px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    opacity: 0.7;
    color: white;
`

const SocialLinks: React.FC = () =>
    <StyledIcons>
        <a href='https://github.com/samdavies'>
            <StyledIcon>
                <FaGithub/>
            </StyledIcon>
        </a>
        <a href='https://uk.linkedin.com/in/sam-davies-203052ba'>
            <StyledIcon>
                <FaLinkedin/>
            </StyledIcon>
        </a>
    </StyledIcons>

export default SocialLinks

/* eslint-disable */
import Head from 'next/head'
import React from 'react'

const SiteHead: React.FC = () => {
    const title = 'Sam Davies'
    return <Head>
        <title key='title'>{title}</title>
        <link key='icon-svg' rel='icon' href='/favicon.svg' type='image/svg+xml'/>
        <link key='icon-png' rel='icon' href='/favicon.png' type='image/png'/>
        <meta key='char-set' charSet='utf-8'/>
        <meta
            key='description'
            name='description'
            content='Personal website for Sam Davies'
        />
    </Head>
}

export default SiteHead
